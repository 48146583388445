/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import media from './media';

const HeroGrid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  /* 
   * how do we set these independent of height of container 
   */

  /* this is what everything is currently useing */
  grid-template-rows: repeat(6, 33.333vw);
  /* this is what heros use */
  grid-template-rows: repeat(6, 1fr);
  /* 
  grid-template-rows: repeat(6, 16.6667vh); 
  replace all grid rows w/ this math
  */

  /* 768 */
  ${media.md`
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(4, 1fr);
  `}

  /* Desktop */
  ${media.lg`
    height: 100vh;
    padding-top: 0;

    /* Grid Styles */
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 25vh);
    
    /* Max Width */
    /* max-width: 1400px; */
    /* max-height: 900px; */
    margin: 0 auto;

  `}

  /* ${media.xl`
    grid-template-rows: repeat(4, 225px);
  `} */
`;

export default HeroGrid;

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import media from './media'

const StyledHeroCTA = styled(animated.div)`
  grid-area: cta;
  padding: 0 24px;
  padding-right: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* no mb, for vertical flex alignment */
  > h2 {
    margin-bottom: 0;
    /* why? */
    max-width: initial;
  }

  /* max width for mobiel/tablet */
  max-width: 412px;
  margin: 0 auto;

  /* 768 */
  ${media.md`
    /* force wrap */
    max-width: 520px;
    /* equal margins */
    padding: 0 60px; 
  `}

  /* 1024 */
  ${media.lg`
    max-width: none;
    max-width: 600px;
    margin: 0 auto 0 0;
    padding: 0 60px;
    
    /* why aren't these in the styled styles? */
    h2 {
      font-size: 46px;
      letter-spacing: 1px;
      line-height: 44px;
      max-width: 600px;
    }
  `}
`

const HeroCTA = ({ title, headline, animateLoadingState: animate = false }) => {
  const spring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: {
      opacity: 0,
      transform: 'translateY(100px)',
    },
  })

  return (
    <StyledHeroCTA className="item item5 cta" style={animate ? spring : {}}>
      <Styled.h5>{title}</Styled.h5>
      <Styled.h2>{headline}</Styled.h2>
    </StyledHeroCTA>
  )
}

HeroCTA.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  animateLoadingState: PropTypes.any,
}

export default HeroCTA
